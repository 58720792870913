export { isNonEmptyString } from './isNonEmptyString';
export { isIosApp } from './stitchfixApp';
export { isLocal } from './isLocal';
export { getParams } from './getParams';
export { themeMapping } from './themeMapping';
export const REMEMBER_USER_COOKIE_NAME = 'remember_user_token' as const;
export const ACCEPT_INVITE_COOKIE_NAME = 'referral_redesign_title' as const;
export const REGISTRATION_WAF_FEATURE_FLAG_NAME =
  'visitor.web.enable_registration_waf' as const;
export const IOS_APP_AUTH_COOKIE_OVERRIDE_FEATURE_FLAG_NAME =
  'client.web.auth_cookie_override_ios_app' as const;
export const PASSKEYS_ENABLED_VISITOR_FEATURE_FLAG_NAME =
  'visitor.web.enable_passkeys' as const;
export const STYLEFILE_FEEDBACK_CONFIG_PARAM_NAME =
  'eng.kufak.onboarding_stylefile_feedback' as const;
export const EXPRESS_PAYMENT_CONFIG_PARAM_NAME = 'cob.express_payment' as const;
export const PAYMENT_MESSAGING_CONFIG_PARAM_NAME =
  'cob.payment_messaging' as const;
export const CONTEXTUAL_ONBOARDING_CONFIG_PARAM_NAME =
  'onb.contextualized_onboarding_questions' as const;
export const LFF_NAVIGATION_CONFIG_PARAM_NAME = 'cob.lff_navigation' as const;
export const HOUSEHOLD_PROFILES_FEATURE_FLAG_NAME =
  'household_profiles_buying_for_others' as const;
